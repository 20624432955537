import React from "react"
import { Link } from "gatsby"

const TypeB = ({
  borderTopName,
  ctaTitle,
  ctaSubTitle,
  ctaText,
  ctaImage,
  ctaImageAlt,
  pdfLink,
  buttonText,
  buttonURL,
  className = '',
  captionClass = '',
  ...props
}) => {
  return (
    <div className={`callout-type-b ${className}`}>
      <div  {...props}
        className={`callout-outer-wrapper ${borderTopName ? borderTopName : ""}`}>
        <div className="callout-inner-wrapper">
          {ctaTitle ? (
            <h4 className="callout-title" dangerouslySetInnerHTML={{ __html: ctaTitle }} />
          ) : ""}

          {ctaSubTitle ? (
            <h5 className="sub-callout-title" dangerouslySetInnerHTML={{ __html: ctaSubTitle }}
            />
          ) : ""}

          {ctaText ? (
            <p className="callout-text" dangerouslySetInnerHTML={{ __html: ctaText }}
            />
          ) : ""}


          {ctaImage ? (
            <div className="callout-media">
              <img src={ctaImage} alt={ctaImageAlt} loading="lazy" />
              <p className={`img-caption ${captionClass}`}>Not actual size.</p>
            </div>
          ) : ""}

          {pdfLink ? (
            <div className="pdf-link">
              <p>Click for <a href={pdfLink} target="_blank" rel="noopener noreferrer">Full Prescribing Information</a>.</p>
            </div>
          ) : ""}

        </div>
        {buttonText ? (
          <button className="btn-cout"><Link to={buttonURL}>{buttonText}</Link></button>
        ) : ""}

      </div>
    </div>
  )
}

export default TypeB;
