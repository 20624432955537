import React from "react";
import useDeviceSize from "hooks/useDeviceSize";

const Hero = ({ children, className, heroImg, heroImgAlt, heroMobileImg, heroMobileImgAlt, ...props }) => {
	const deviceSizes = useDeviceSize();
	return (
		<div {...props} className={`hero-content ${className ? className : ""}`}>
			<div className="caption-wrapper">{children}</div>
			<div className="hero-img">
				{deviceSizes?.smUp ? <img src={heroImg} alt={heroImgAlt} />
					: <img src={heroMobileImg} alt={heroMobileImgAlt} />}
				<div className="actor-text">Actor portrayal.</div>
			</div>
		</div>
	);
};

export default Hero;
