import React, { useState } from "react";
// import { appContext } from 'providers/appProvider';
// import { Portal } from "react-portal";
import Modal from 'react-awesome-modal';
import ReactPlayer from 'react-player';
// import videoUrls from "../../../../videoUrls.config";

function VideoModal({ url, videoThumbnail, ...props }) {
  return (
    <div className="modal-container">
      <Modal visible={props.open} onClickAway={() => props.closeModal()}>
        <div className="video-container">
          <div className="video-header">
            <div className="close" onClick={() => props.closeModal()}>
              ×
            </div>
          </div>
          <div className="video-content">
            <ReactPlayer
              className="react-player"
              url={url}
              controls={true}
              // light = {videoThumbnail}
              playing={props.open}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default VideoModal;
