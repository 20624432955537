import React from "react";

const TypeA = ({
  className,
  imgRef,
  imgAlt,
  ctaTitle,
  ctaText,
}) => {
  return (
    <div className="callout-type-a">
      <div className={`callout-wrapper ${className ? className : ""}`}>
        <div className="image-wrapper">
          <img
            src={imgRef}
            alt={imgAlt}
          />
        </div>
        <h4 className="callout-title" dangerouslySetInnerHTML={{ __html: ctaTitle }} />
        <p className="callout-text" dangerouslySetInnerHTML={{ __html: ctaText }}
        />
      </div>
    </div>
  )
}

export default TypeA
